/* Burger icon styles */
.menu-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 15px;
}
  
.menu-icon .bar {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

@media screen and (max-width: 768px) {
    .menu-icon {
      display: block;
    }
  
    .side-menu {
      width: 0;
      left: -250px;
    }
  
    .side-menu.show {
      width: 250px;
      left: 0;
    }
}
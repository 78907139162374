.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 220px;
    height: 150px;
    color: grey;
    font-family: "Montserrat";
    background-color: whitesmoke;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding:10px;
}
.upload p {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
}
.upload.is-highlight {
    background-color: rgba(74, 160, 234, 0.5);
}
.upload.is-highlight p {
    opacity: 1;
}
.upload.is-drop p {
    opacity: 0;
}
.upload-button {
    width: 100%;
    position: absolute;
    margin:10px 0;
    bottom: 0;
    left: 0;
}
.upload-file {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.image-preview{
    max-height: 200px;
    max-width: 200px;
}
.blog-title{
    text-shadow:0 1px 0px rgb(3, 3, 3);
}